import {
    Directive,
    OnInit,
    Input,
    ElementRef,
    inject,
    OnChanges,
    EventEmitter,
    HostBinding,
    HostListener,
    Output
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from './pipes';
import { HelpersService } from '../shared/services/helpers.service';
import { createPopper, Instance } from '@popperjs/core';

@Directive({
    selector: '[legacyLinkInner]',
    standalone: true
})
export class LegacyLinkInnerDirective implements OnInit {
    @Input() legacy: boolean;
    constructor(private el: ElementRef) {}

    fn = (e: PointerEvent) => {
        if (this.legacy) {
            e.preventDefault();
            e.stopPropagation();
            const parent = this.el.nativeElement.closest('a');
            if (parent?.href) {
                if (e.ctrlKey || e.metaKey) {
                    window.open(parent.href);
                } else window.location.href = parent.href;
            }
        }
    };

    ngOnInit() {
        this.el.nativeElement.addEventListener('click', this.fn);
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('click', this.fn);
    }
}

/*
    no use cases atm.
    use case: [href] or href attributes that start with a string, are replaced with the api.medbook url on-compile (on staging and onward).
    internal angular routing + href: be sure to start with a variable.
    external api routing + href: be sure to NOT start with a variable.
    global match query: [href]="'/
*/
@Directive({
    selector: '[reloadOnRouterInnerLink]',
    standalone: true
})
export class ReloadOnRouterlinkInnerDirective implements OnInit {
    constructor(private el: ElementRef) {}

    fn = (e: PointerEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const parent = this.el.nativeElement.closest('a');
        if (parent?.href) {
            if (e.ctrlKey || e.metaKey) {
                window.open(parent.href);
            } else window.location.href = parent.href;
        }
    };

    ngOnInit() {
        this.el.nativeElement.addEventListener('click', this.fn);
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('click', this.fn);
    }
}

@Directive({
    selector: '[disableOnDownload]',
    standalone: true
})
export class DisableOnDownloadDirective implements OnInit {
    @Input() timeout?: number;
    @Input() toast?: string;

    constructor(private el: ElementRef, private toastrService: ToastrService, private translatePipe: TranslatePipe) {}

    fn = () => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        (that.el.nativeElement as HTMLElement).classList.add('disabled');
        (that.el.nativeElement as HTMLElement).setAttribute('disabled', '');
        if (that.toast) setTimeout(() => that.toastrService.success(that.toast), 200);
        setTimeout(() => {
            (that.el.nativeElement as HTMLElement).classList.remove('disabled');
            (that.el.nativeElement as HTMLElement).removeAttribute('disabled');
        }, that.timeout ?? 800);
    };

    ngOnInit() {
        this.el.nativeElement.addEventListener('click', this.fn);
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('click', this.fn);
    }
}

@Directive({
    selector: '[copyOnClick]',
    standalone: true
})
export class CopyOnClickDirective implements OnInit {
    @Input() copy: string;
    @Input() toast?: string;

    helpersService = inject(HelpersService);

    constructor(private el: ElementRef, private toastrService: ToastrService, private translatePipe: TranslatePipe) {}

    fn = (event: PointerEvent) => {
        event.preventDefault();
        event.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        that.helpersService.copyToClipboard(that.copy, that.toast);
    };

    ngOnInit() {
        this.el.nativeElement.addEventListener('click', this.fn);
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('click', this.fn);
    }
}

@Directive({
    selector: '[ellipsisTooltip]',
    standalone: true
})
export class EllipsisTooltipDirective implements OnChanges {
    @Input() ellipsisTooltip: string | number = '';

    shown = false;
    tooltip: HTMLElement;
    popperInstance: Instance;
    constructor(private el: ElementRef) {}

    show = () => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        if (!that.hasEllipsis()) return;
        that.tooltip.setAttribute('data-show', '');
        this.popperInstance.setOptions((options) => ({
            ...options,
            modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }]
        }));
        this.popperInstance.update();
        that.shown = true;
    };

    hide = () => {
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            that.tooltip.removeAttribute('data-show');
            this.popperInstance.setOptions((options) => ({
                ...options,
                modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }]
            }));
            that.shown = false;
        }, 1);
    };

    ngOnChanges() {
        this.tooltip?.remove();
        this.init();
    }

    init() {
        setTimeout(() => {
            if (!this.ellipsisTooltip) return;
            const el = document.createElement('div');
            el.className = 'ellipsis-tooltip';
            el.innerHTML = `${this.ellipsisTooltip}`;
            document.body.appendChild(el);
            this.tooltip = el;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;

            this.popperInstance = createPopper(this.el.nativeElement, <HTMLElement>this.tooltip, {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0]
                        }
                    }
                ]
            });

            const showEvents = ['mouseenter', 'focus'];
            const hideEvents = ['mouseleave', 'blur'];

            showEvents.forEach((event) => {
                this.el.nativeElement.addEventListener(event, this.show);
            });

            hideEvents.forEach((event) => {
                this.el.nativeElement.addEventListener(event, this.hide);
            });
        }, 1);
    }

    hasEllipsis() {
        return this.el.nativeElement.offsetWidth < this.el.nativeElement.scrollWidth;
    }

    ngOnDestroy() {
        setTimeout(() => {
            const showEvents = ['mouseenter', 'focus'];
            const hideEvents = ['mouseleave', 'blur'];

            showEvents.forEach((event) => {
                this.el.nativeElement.addEventListener(event, this.show);
            });

            hideEvents.forEach((event) => {
                this.el.nativeElement.addEventListener(event, this.hide);
            });
            this.tooltip?.remove();
            this.popperInstance?.destroy();
        }, 1);
    }
}

@Directive({
    selector: '[fileDrop]',
    standalone: true
})
export class FileDropDirective {
    @HostBinding('class.file-over') fileOver: boolean;
    @Output() fileDropped = new EventEmitter<FileList>();

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
